import { graphql } from 'gatsby'
import React from "react"
import { getField } from '../../helper.js'
import Container from '../container'
import HeadlineMain from '../headline/main'
import PostsCarousel from '../posts/carousel'

const Promo = ({ titleDe, titleEn, actions, items, locale }) => {
  const title = getField({ titleDe, titleEn }, "title", locale)
  return (
    <section>
      <Container>
        <div>
          <HeadlineMain
            headline={title}
            actions={actions}
            useBorder={false}
          />
        </div>
      </Container>
      <PostsCarousel posts={items} locale={locale} />
    </section>
  )
}

export default Promo

export const query = graphql`
  fragment Promo on CMSPromo {
    id
    titleDe
    titleEn
    items {
      __typename
      ... on CMSRecipe {
        ...PromoRecipe
      }
      ... on CMSPost {
        ...PromoPost
      }
      ... on CMSPage {
        ...PromoPage
      }
    }
  }
`
